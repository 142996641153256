import * as Sentry from '@sentry/react';
import { useSetAtom } from 'jotai';
import React, {
  Suspense,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getPhone } from '@/lib/getPhone.ts';
import Header from '../Header/Header.tsx';
import RestaurantName, {
  RestaurantNameLoader,
} from '../RestaurantName/RestaurantName.tsx';
import { ApiError } from '@/lib/ApiError.ts';
import { AuthAtom, UserAtom, useWidgetState } from '../lib/state.ts';
import { updateUser, verification, verify } from '@/lib/api.ts';
import debounce from 'lodash/debounce';
import OtpInput from 'react-otp-input';
import styles from './PhoneConfirm.module.css';

const PhoneConfirm: React.FC = () => {
  const navigate = useNavigate();
  const [sending, setSending] = useState<boolean | null>(null);
  const [widget, updateWidget] = useWidgetState();
  const setAuth = useSetAtom(AuthAtom);
  const setUser = useSetAtom(UserAtom);
  const pin_ref = useRef<HTMLInputElement[]>(null);
  const [otp, setOtp] = useState('');

  const onResendClick = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void onResend();
  };

  const onResend = async () => {
    updateWidget({
      phone_confirmed: null,
    });

    setSending(true);

    await verify(widget.phone_number!, '');

    setSending(false);

    setTimeout(() => setSending(null), 5000);
  };

  const onComplete = async (code: string) => {
    try {
      const verification_response = await verification(
        widget.phone_number!,
        code
      );

      Sentry.setUser({
        id: verification_response.user,
      });

      setAuth(verification_response);

      const user_response = await updateUser(verification_response.token, {
        first_name: widget.first_name!,
        last_name: widget.last_name!,
        email: widget.email!,
      });

      setUser({
        first_name: user_response.user.first_name,
        last_name: user_response.user.last_name,
        email: user_response.user.email,
        phone: widget.phone_number,
        referral_code: widget.referral_code,
      });

      updateWidget({
        phone_confirmed: true,
      });

      setTimeout(() => navigate(`../eventpayment`), 500);
    } catch (e: unknown) {
      if (e instanceof ApiError && e.type === 'UpdateUserErrorResponse') {
        const update_user_error =
          e.message === 'The email has already been taken.'
            ? 'The given email address is associated with a Dorsia Membership with a different phone number.'
            : e.message;

        updateWidget({
          update_user_error,
        });

        navigate(`../info`);
      } else if (e instanceof Error) {
        Sentry.captureException(e);
        console.error(e.message);
      }

      updateWidget({ phone_confirmed: false });

      if (pin_ref.current) {
        pin_ref.current.forEach(
          (input: HTMLInputElement) => (input.value = '')
        );
        pin_ref.current[0].focus();
      }
    }
  };

  useEffect(() => {
    if (otp.length === 4) {
      debouncedAuth(otp);
    }
  }, [otp]);

  const debouncedAuth = useCallback(debounce(onComplete, 300), []);
  const phone = getPhone(widget.phone_number, widget.phone_country);

  if (!phone) {
    return <></>;
  }

  return (
    <>
      <Header title="Enter your details" step={2} show_image={false} />

      <div className="content">
        <Suspense fallback={<RestaurantNameLoader />}>
          <RestaurantName />
        </Suspense>

        <div className="section">
          <Link to="../info" className={styles.back}>
            {'<-'} Back
          </Link>

          <h3>
            We sent a 4-digit code to{' '}
            <span className={styles.noWrap}>
              {!!phone && phone.formatNational()}
            </span>
          </h3>

          <p className={styles.info}>Enter the 4-digit code</p>

          <div className={styles.pinFieldContainer}>
            <OtpInput
              shouldAutoFocus
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={styles.pinField}
              skipDefaultStyles
              inputType="number"
            />
          </div>

          {widget.phone_confirmed === false && (
            <div className={styles.error}>
              The code you entered is incorrect
            </div>
          )}

          {sending === true && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Sending...
            </a>
          )}
          {sending === false && (
            <a href="/" className={styles.again}>
              Sent
            </a>
          )}
          {sending === null && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Send Again
            </a>
          )}

          <button className="nextButton" disabled={!widget.phone_confirmed}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default PhoneConfirm;
