import * as Sentry from '@sentry/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { computeAvailability } from '../../../lib/computeAvailability.ts';
import {
  getPaymentMethods,
  getReservationConfirmation,
  getRestaurant,
  getUser,
} from '../../../lib/api.ts';
import { ApiError } from '../../../lib/ApiError.ts';
import {
  ConfirmationResponseReservation,
  ReservationResponseReservation,
  RestaurantResponseData,
  RestaurantResponseMeta,
  AuthResponse,
  Post_Reserve,
  PaymentMethod,
} from '../../../lib/types.ts';
import usePromise from 'react-promise-suspense';
import { UserDetails, WIDGET_STATE_VERSION, WidgetState } from './types.ts';

dayjs.extend(isToday);

export const AuthAtom = atomWithStorage<AuthResponse | null>(
  'widget_auth',
  null
);
export const UserAtom = atom<UserDetails>({});
export const RestaurantIdAtom = atom<number | null>(null);
export const RestaurantAtom = atom<RestaurantResponseData | null>(null);
export const RestaurantMetaAtom = atom<RestaurantResponseMeta | null>(null);
export const RestaurantConfirmationAtom =
  atom<ConfirmationResponseReservation | null>(null);
export const ReservationAtom = atom<ReservationResponseReservation | null>(
  null
);

export const EventReservationAtom = atom<any | null>(null);
export const LoadPaymentMethodsAtom = atom<Dayjs>(dayjs());
export const PaymentMethodsAtom = atom<PaymentMethod[]>([]);
export const PaymentMethodsByIdAtom = atom<Record<string, PaymentMethod>>({});

const getExpiration = (): Dayjs => dayjs().add(30, 'minute');

export const WidgetStateAtom = atomWithStorage<WidgetState>(
  'widget_reservation',
  {
    version: WIDGET_STATE_VERSION,
    expires: getExpiration(),
    phone_country: 'US',
    phone_confirmed: null,
    policy_accepted: null,
    event: undefined,
    eventInfo: undefined,
    referral_code: undefined,
  }
);

export const updateWidgetStateAtom = atom(
  null,
  (get, set, update: Partial<WidgetState>) => {
    const currentState = get(WidgetStateAtom);
    set(WidgetStateAtom, {
      ...currentState,
      ...update,
      expires: getExpiration(),
    });
  }
);

export const resetWidgetStateAtom = atom(null, (_get, set) => {
  set(WidgetStateAtom, {
    version: WIDGET_STATE_VERSION,
    expires: getExpiration(),
    phone_country: 'US',
    phone_confirmed: null,
    policy_accepted: null,
    event: undefined,
    eventInfo: undefined,
    referral_code: undefined,
  });
});

export const reloadPaymentMethodsAtom = atom(null, (_get, set) => {
  set(LoadPaymentMethodsAtom, dayjs());
});

export const useWidgetState = (): [
  WidgetState,
  (partial: Partial<WidgetState>) => void
] => {
  const widget = useAtomValue(WidgetStateAtom);
  const setWidgetState = useSetAtom(updateWidgetStateAtom);

  const update = (partial: Partial<WidgetState>) => {
    setWidgetState(partial);
  };

  return [widget, update];
};

export const useRestaurantId = () => {
  const { id } = useParams();
  const [restaurant_id, setRestaurantId] = useAtom(RestaurantIdAtom);

  useEffect(() => {
    if (id) {
      const parsed = parseInt(id, 10);
      if (parsed != restaurant_id) {
        setRestaurantId(parsed);
      }
    }
  }, [id]);

  return restaurant_id;
};

// Updating this key will force an API hit to load the latest restaurant availability
let RELOAD_RESTAURANT_KEY = dayjs().format();

export const reloadRestaurantKey = () => {
  RELOAD_RESTAURANT_KEY = dayjs().format();
};

export const useResetState = (): (() => void) => {
  const resetReservation = useSetAtom(resetWidgetStateAtom);

  return () => {
    reloadRestaurantKey();
    resetReservation();
  };
};

export const useRestaurant = () => {
  const NOW = dayjs();
  const id = useRestaurantId();
  const [widget] = useWidgetState();
  const [restaurant, setRestaurant] = useAtom(RestaurantAtom);
  const [meta, setMeta] = useAtom(RestaurantMetaAtom);

  let og_date = NOW.format('YYYY-MM-DD');
  let date = dayjs();
  let time = '0:00';

  if (widget.date) {
    og_date = widget.date;
    date = dayjs(og_date);
  }

  if (date.isToday()) {
    time = NOW.format('H:mm');
  } else if (
    restaurant?.operating_hours !== undefined &&
    restaurant.operating_hours.length > 0
  ) {
    const day = date.day();

    if (restaurant.operating_hours[day]?.length) {
      time = restaurant.operating_hours[day][0].start;
    }
  }

  const response = usePromise(getRestaurant, [
    id,
    og_date,
    time,
    RELOAD_RESTAURANT_KEY,
  ]);

  useEffect(() => {
    if (response?.data) {
      if (response.data.id != restaurant?.id) {
        setRestaurant(response.data);
      }
    }

    if (response?.meta) {
      const r_length = response.meta.availability?.length || 0;
      const m_length = meta?.availability?.length ?? 0;
      if (
        response.meta?.date !== meta?.date ||
        response.meta?.availability[0]?.uid !== meta?.availability[0]?.uid ||
        r_length !== m_length
      ) {
        if (response.meta?.availability) {
          setMeta(computeAvailability(response.meta));
        } else {
          setMeta(response.meta);
        }
      }
    }
  }, [response?.meta]);

  return {
    restaurant,
    meta,
  };
};

export const useConfirmEvent = () => {
  const id = useRestaurantId();
  const auth = useAtomValue(AuthAtom);
  const navigate = useNavigate();
  const [widget, updateWidget] = useWidgetState();
  const [confirmation, setConfirmation] = useAtom(RestaurantConfirmationAtom);

  useEffect(() => {
    const get = async () => {
      if (id && auth && widget?.confirm_body) {
        try {
          const response = await getReservationConfirmation(
            auth.token,
            id,
            widget.confirm_body
          );
          setConfirmation(response.reservation);

          const reserve: Post_Reserve = {
            restaurant: response.reservation.restaurant.id,
            seating: response.reservation.seating.id,
            seats: response.reservation.party,
            date: response.reservation.date_full,
            confirmed_price: response.reservation.price_per_person,
          };

          if (widget.payment_method) {
            reserve.payment_method_id = widget.payment_method;
          }

          updateWidget({
            reserve_body: reserve,
          });
        } catch (e: unknown) {
          if (e instanceof ApiError && e.type === 'ReserveErrorResponse') {
            updateWidget({
              payment_error: e.message,
            });

            navigate(`../payment`);
          } else if (
            e instanceof ApiError &&
            (e.type === 'ReservationUnavailableErrorResponse' ||
              e.type === 'ReservationResourceNotFound' ||
              e.type === 'AlreadyCanceledReservationErrorResponse')
          ) {
            reloadRestaurantKey();

            updateWidget({
              availability: undefined,
              option: undefined,
              reservation_error: e.message,
            });

            // navigate(`../booking`);
          } else if (e instanceof Error) {
            console.error('Unrecognized Error', e.message);
            Sentry.captureException(e);
            navigate(`../payment`);
          }
        }
      }
    };

    void get();
  }, [
    id,
    auth?.token,
    widget?.confirm_body?.restaurant_id,
    widget?.confirm_body?.date,
    widget?.confirm_body?.time,
    widget?.confirm_body?.party,
    widget?.confirm_body?.seating_id,
  ]);

  return confirmation;
};

export const useConfirmRestaurant = () => {
  const id = useRestaurantId();
  const auth = useAtomValue(AuthAtom);
  const navigate = useNavigate();
  const [widget, updateWidget] = useWidgetState();
  const [confirmation, setConfirmation] = useAtom(RestaurantConfirmationAtom);

  useEffect(() => {
    const get = async () => {
      if (id && auth && widget?.confirm_body) {
        try {
          const response = await getReservationConfirmation(
            auth.token,
            id,
            widget.confirm_body
          );
          setConfirmation(response.reservation);

          const reserve: Post_Reserve = {
            restaurant: response.reservation.restaurant.id,
            seating: response.reservation.seating.id,
            seats: response.reservation.party,
            date: response.reservation.date_full,
            confirmed_price: response.reservation.price_per_person,
          };

          if (widget.payment_method) {
            reserve.payment_method_id = widget.payment_method;
          }

          updateWidget({
            reserve_body: reserve,
          });
        } catch (e: unknown) {
          if (e instanceof ApiError && e.type === 'ReserveErrorResponse') {
            updateWidget({
              payment_error: e.message,
            });

            navigate(`../payment`);
          } else if (
            e instanceof ApiError &&
            (e.type === 'ReservationUnavailableErrorResponse' ||
              e.type === 'ReservationResourceNotFound')
          ) {
            reloadRestaurantKey();

            updateWidget({
              availability: undefined,
              option: undefined,
              reservation_error: e.message,
            });

            // navigate(`../booking`);
          } else if (e instanceof Error) {
            console.error('Unrecognized Error', e.message);
            Sentry.captureException(e);
            navigate(`../payment`);
          }
        }
      }
    };

    void get();
  }, [
    id,
    auth?.token,
    widget?.confirm_body?.restaurant_id,
    widget?.confirm_body?.date,
    widget?.confirm_body?.time,
    widget?.confirm_body?.party,
    widget?.confirm_body?.seating_id,
  ]);

  return confirmation;
};

export const useUserDetails = () => {
  const auth = useAtomValue(AuthAtom);
  const [user, setUser] = useAtom(UserAtom);
  const widget = useAtomValue(WidgetStateAtom);
  const setWidget = useSetAtom(updateWidgetStateAtom);

  useEffect(() => {
    const go = async () => {
      const response = await getUser(auth!.token);

      if (!response?.user) {
        return;
      }

      Sentry.setUser({
        id: response.user.uuid,
        email: response.user.email,
      });

      setUser({
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        email: response.user.email,
        phone: response.user.phone,
      });

      setWidget({
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        email: response.user.email,
        phone_number: response.user.phone,
        phone_country: widget.phone_country ?? 'US',
      });
    };

    if (auth?.token) {
      void go();
    }
  }, [auth?.token]);

  return user;
};

export const usePaymentMethods = () => {
  const auth = useAtomValue(AuthAtom);
  const reload = useAtomValue(LoadPaymentMethodsAtom);
  const setMethods = useSetAtom(PaymentMethodsAtom);
  const setMethodsById = useSetAtom(PaymentMethodsByIdAtom);

  useEffect(() => {
    if (!auth?.token) {
      return;
    }

    const go = async () => {
      try {
        const response = await getPaymentMethods(auth.token);

        if (!response?.data) {
          setMethods([]);
          setMethodsById({});
          return;
        }

        setMethods(response.data);

        const by_id = {};
        response.data.map((method) => (by_id[method.id] = method));
        setMethodsById(by_id);
      } catch (e: unknown) {
        Sentry.captureException(e);
      }
    };

    void go();
  }, [auth?.token, reload]);
};
