import {
  AlreadyCanceledReservationErrorResponse,
  ReservationReceiptNotReady,
  ReservationUnavailableErrorResponse,
  ProcessingPaymentErrorResponse,
  ReserveErrorResponse,
  ServerErrorResponse,
  UnauthorizedErrorResponse,
  UnfinishedUserProfileErrorResponse,
  UpdateUserErrorResponse,
  CardErrorResponse,
  ActionRequiredResponse,
  ReservationResourceNotFound,
} from './types.ts';

type Responses =
  | ReserveErrorResponse
  | UpdateUserErrorResponse
  | ReservationUnavailableErrorResponse
  | ProcessingPaymentErrorResponse
  | ActionRequiredResponse
  | ServerErrorResponse
  | UnauthorizedErrorResponse
  | UnfinishedUserProfileErrorResponse
  | AlreadyCanceledReservationErrorResponse
  | ReservationReceiptNotReady
  | CardErrorResponse
  | ReservationResourceNotFound;

type ResponseNames =
  | 'ReserveErrorResponse'
  | 'UpdateUserErrorResponse'
  | 'ReservationUnavailableErrorResponse'
  | 'ProcessingPaymentErrorResponse'
  | 'ServerErrorResponse'
  | 'ActionRequiredResponse'
  | 'UnauthorizedErrorResponse'
  | 'UnfinishedUserProfileErrorResponse'
  | 'AlreadyCanceledReservationErrorResponse'
  | 'ReservationReceiptNotReady'
  | 'CardErrorResponse'
  | 'ReservationResourceNotFound';

interface ApiErrorParams {
  url: URL;
  response: Responses;
}

export class ApiError extends Error {
  url: URL;
  response: Responses;
  type: ResponseNames | 'Error';

  constructor(params: ApiErrorParams) {
    super();
    this.url = params.url;
    this.response = params.response;

    this.type = ApiError.match(this.response) || 'Error';

    switch (this.type) {
      case 'ServerErrorResponse':
        this.message = (this.response as ServerErrorResponse).message;
        break;

      case 'ReservationReceiptNotReady':
        this.message = (this.response as ReservationReceiptNotReady).message;
        break;

      case 'AlreadyCanceledReservationErrorResponse':
        this.message = (
          this.response as AlreadyCanceledReservationErrorResponse
        ).message;
        break;

      case 'UnfinishedUserProfileErrorResponse':
        this.message = (
          this.response as UnfinishedUserProfileErrorResponse
        ).message;
        break;

      case 'UnauthorizedErrorResponse':
        this.message = (this.response as UnauthorizedErrorResponse).message;
        break;

      case 'ReserveErrorResponse':
        this.message = (this.response as ReserveErrorResponse).message;
        break;

      case 'UpdateUserErrorResponse':
        this.message = (
          this.response as UpdateUserErrorResponse
        ).errors.email[0];
        break;

      case 'ReservationUnavailableErrorResponse':
        this.message = (
          this.response as ReservationUnavailableErrorResponse
        ).message;
        break;

      case 'ProcessingPaymentErrorResponse':
        this.message = (
          this.response as ProcessingPaymentErrorResponse
        ).message;
        break;

      case 'CardErrorResponse':
        this.message = (this.response as CardErrorResponse).message;
        break;
      case 'ActionRequiredResponse':
        this.response = this.response as ActionRequiredResponse;
        break;
    }
  }

  static match = <ResponseT>(
    response: ReserveErrorResponse | UpdateUserErrorResponse | ResponseT
  ): ResponseNames | false => {
    const type = (response as ReserveErrorResponse).type ?? undefined;

    const message =
      (
        response as
          | ReservationUnavailableErrorResponse
          | ProcessingPaymentErrorResponse
          | ServerErrorResponse
          | UnauthorizedErrorResponse
          | UnfinishedUserProfileErrorResponse
          | AlreadyCanceledReservationErrorResponse
          | ReservationReceiptNotReady
          | ActionRequiredResponse
          | CardErrorResponse
      ).message ?? undefined;
    const email = (response as UpdateUserErrorResponse).errors?.email ?? [];

    switch (true) {
      case !!type:
        return 'ReserveErrorResponse';

      case message === 'Please tell us a little about yourself.':
        return 'UnfinishedUserProfileErrorResponse';

      case message === "Reservation doesn't have an order created":
        return 'ReservationReceiptNotReady';

      case message === 'Reservation has been cancelled.':
        return 'AlreadyCanceledReservationErrorResponse';

      case message === 'Reservation has already been canceled':
        return 'AlreadyCanceledReservationErrorResponse';

      case message === 'This reservation is no longer available.':
        return 'ReservationUnavailableErrorResponse';

      case message === 'Reservation is sold out.':
        return 'ReservationUnavailableErrorResponse';

      case message === 'Reservation has been claimed.':
        return 'ReservationUnavailableErrorResponse';

      case message === 'Reservation payment in progress.':
        return 'ProcessingPaymentErrorResponse';

      case message === 'This action is unauthorized.':
        return 'UnauthorizedErrorResponse';

      case message === 'Payment processing requires action.':
        return 'ActionRequiredResponse';

      case message === 'Server Error':
        return 'ServerErrorResponse';

      case message ===
        'There was an error processing your payment. Please verify your card information or use another card.':
        return 'CardErrorResponse';

      case email.length > 0:
        return 'UpdateUserErrorResponse';
    }

    return false;
  };
}
