// import React from "react";
import React from 'react';
import Header from "../Header/Header.tsx";

import styles from './ProcessingPayment.module.css';

const ProcessingPayment: React.FC = () => {
    return (
        <>
            <Header title="Payment is being processed" step={0} show_image={false} />

            <div className="content">
                <div className="section">
                    <p className={styles.text}>Your payment is being processed. This can take up to five minutes. Please click below to check your payment status.</p>

                    <a href="../confirm" className="nextButton widthAuto">Check Status</a>
                </div>
            </div>

        </>
    )
}

export default ProcessingPayment;
