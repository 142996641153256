import React from 'react';
import { useRestaurant } from '../../lib/state.ts';
import styles from './HeaderImage.module.css';
import Config from '@/lib/Config.ts';
import solImage from '../../../../assets/images/events/SolaSolPoster12-04-2024.jpg';

export const HeaderImageLoader: React.FC = () => {
  return (
    <div className={styles.image} style={{ backgroundColor: '#DFDFDF' }}></div>
  );
};

const HeaderImage: React.FC = () => {
  const { restaurant } = useRestaurant();

  if (!restaurant) {
    return <HeaderImageLoader />;
  }

  let mainimage =
    restaurant.wide_crop_image_url || restaurant.tall_crop_image_url;

  if (
    Config.data.environment === 'staging' ||
    (Config.data.environment === 'local' && restaurant.id === 337) ||
    (Config.data.environment === 'production' && restaurant.id === 588)
  ) {
    mainimage = solImage;
  }

  return (
    <div>
      <img
        src={mainimage}
        className={styles.image}
        alt={restaurant.name}
        width="100%"
      />
    </div>
  );
};

export default HeaderImage;
