import {useAtomValue} from "jotai";
import React from "react";
import Config from "../../../lib/Config.ts";
import Header from "../Header/Header.tsx";
import {NowAtom, ReservationAtom} from "../lib/state.ts";

import styles from './Expired.module.css';

const Expired: React.FC = () => {
    const reservation = useAtomValue(ReservationAtom);
    const now = useAtomValue(NowAtom);
    return (
        <>
            <Header title="This booking link has expired" step={0} show_image={false} />

            <div className="content">
                <div className="section">
                    <p className={styles.text}>Sorry, but this link is no longer available.  Please contact your reservationist.</p>
                    <a href="./confirm" className="nextButton widthAuto">Try Again</a>
                </div>

                {Config.data.environment !== 'production' && !!reservation && (
                    <div className="section">
                        <h3>Non-production Debugging info</h3>

                        <code>Exp: {reservation.claim_deadline_at_utc.format()}</code>
                        <code>Now: {now.format()}</code>
                        <code>---</code>
                        <code>Difference: {reservation.claim_deadline_at_utc.diff(now, 'second')} seconds</code>
                    </div>
                )}
            </div>

        </>
    )
}

export default Expired
