import { Provider as JotaiProvider } from 'jotai';
import { createStore } from 'jotai';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import 'unfonts.css';
import './index.css';

import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Params,
  redirect,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { PhoneAtom as DLPhoneAtom } from './apps/DirectLink/lib/state.ts';
import {
  AuthAtom,
  PhoneAtom as FLPhoneAtom,
} from './apps/Frictionless/lib/state.ts';
import { SignatureAtom } from './apps/CancelLink/lib/state.ts';

import { verify } from './lib/api.ts';
import Config from './lib/Config.ts';
import { getPhone } from './lib/getPhone.ts';

import Iframe from './apps/Iframe/Iframe.tsx';

import CancelLink from './apps/CancelLink/CancelLink.tsx';
import CLConfirm from './apps/CancelLink/Confirm/Confirm.tsx';

import BookingUrl from './apps/BookingUrl/BookingUrl.tsx';
import BookingUrlIndex from './apps/BookingUrl/Index/Index.tsx';
import BookingUrlBooking from './apps/BookingUrl/Booking/Booking.tsx';

import Widget from './apps/Widget/Widget.tsx';
import WidgetIndex from './apps/Widget/Index/Index.tsx';
import WidgetBooking from './apps/Widget/Booking/Booking.tsx';
import WidgetInfo from './apps/Widget/Info/Info.tsx';
import WidgetPhoneConfirm from './apps/Widget/PhoneConfirm/PhoneConfirm.tsx';
import WidgetEventPhoneConfirm from './apps/Widget/PhoneConfirm/EventPhoneConfirm.tsx';
import WidgetPayment from './apps/Widget/Payment/Payment.tsx';
import WidgetEventPayment from './apps/Widget/Payment/EventPayment.tsx';
import WidgetConfirm from './apps/Widget/Confirm/Confirm.tsx';
import WidgetConfirmation from './apps/Widget/Confirmation/Confirmation.tsx';
import WidgetEventInfo from './apps/Widget/EventInfo/EventInfo.tsx';
import WidgetEventConfirm from './apps/Widget/EventConfirm/EventConfirm.tsx';
import WidgetEventConfirmation from './apps/Widget/EventConfirmation/EventConformation.tsx';

import DirectLink from './apps/DirectLink/DirectLink.tsx';
import DLPhone from './apps/DirectLink/Phone/Phone.tsx';
import DLAuth from './apps/DirectLink/Auth/Auth.tsx';
import DLInfo from './apps/DirectLink/Info/Info.tsx';
import DLAccept from './apps/DirectLink/Accept/Accept.tsx';
import DLPayment from './apps/DirectLink/Payment/Payment.tsx';
import DLOne01 from './apps/DirectLink/One01/One01.tsx';
import DLConfirm from './apps/DirectLink/Confirm/Confirm.tsx';
import DLConfirmation from './apps/DirectLink/Confirmation/Confirmation.tsx';
import DLExpired from './apps/DirectLink/Expired/Expired.tsx';
import DLUnavailable from './apps/DirectLink/Unavailable/Unavailable.tsx';
import DLProcessingPayment from './apps/DirectLink/ProcessingPayment/ProcessingPayment.tsx';
import DLCanceled from './apps/CancelLink/Canceled/Canceled.tsx';
import DLNotFound from './apps/CancelLink/NotFound/NotFound.tsx';
import { Guest } from './apps/DirectLink/Guest/Guest.tsx';

import Frictionless from './apps/Frictionless/Frictionless.tsx';
import FLPhone from './apps/Frictionless/Phone/Phone.tsx';
import FLAuth from './apps/Frictionless/Auth/Auth.tsx';
import FLDetails from './apps/Frictionless/Details/Details.tsx';
import FLCustomTip from './apps/Frictionless/CustomTip/CustomTip.tsx';

import { AuthResponse } from './lib/types.ts';
import ConciergeMember from '@/apps/Frictionless/Concierge/ConciergeMember.tsx';

const myStore = createStore();

Config.setFromFile()
  .then(() => {
    Sentry.init({
      dsn: 'https://e649d46018280c4947277b6641542e4e@o1071572.ingest.sentry.io/4506084937170944',
      environment: Config.data.environment,
      enabled: Config.data.environment !== 'local',
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/10.0.0.\d:\d+\//,
        /^https:\/\/dorsia-booking.pages.dev\//,
        /^https:\/\/staging.dorsia-booking.pages.dev\//,
        /^https:\/\/book.dorsia.com\//,
        /^https:\/\/booking.dorsia.com\//,
      ],
      // Performance Monitoring Rate
      tracesSampleRate: 0.8,

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    const sentryCreateBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);

    const routes = [
      {
        path: 'reservation/:uuid',
        element: <DirectLink />,
        children: [
          {
            path: '',
            loader: ({ request }: { request: Request }) => {
              const json = localStorage.getItem('dl_auth'); // atomWithStorage, but load it directly to skip the holding pattern while it loads

              const auth: AuthResponse | null = json
                ? (JSON.parse(json) as AuthResponse)
                : null;
              if (auth?.token) {
                return redirect('./accept');
              }

              const number = new URL(request.url).searchParams.get('phone');
              if (number) {
                try {
                  const phone = getPhone(number);
                  if (phone) {
                    myStore.set(DLPhoneAtom, phone);
                    void verify(phone.formatInternational()!);
                    return redirect('./auth');
                  }
                } catch (e) {
                  console.error(e);
                }
              }

              return redirect('./phone');
            },
          },
          {
            path: 'phone',
            element: <DLPhone />,
          },
          {
            path: 'auth',
            element: <DLAuth />,
          },
          {
            path: 'info',
            element: <DLInfo />,
          },
          {
            path: 'accept',
            element: <DLAccept />,
          },
          {
            path: 'payment',
            element: <DLPayment />,
          },
          {
            path: '101',
            element: <DLOne01 />,
          },
          {
            path: 'confirm',
            element: <DLConfirm />,
          },
          {
            path: 'confirmation',
            element: <DLConfirmation />,
          },
          {
            path: 'expired',
            element: <DLExpired />,
          },
          {
            path: 'unavailable',
            element: <DLUnavailable />,
          },
          {
            path: 'processing_payment',
            element: <DLProcessingPayment />,
          },
          {
            path: 'guests',
            element: <Guest />,
          },
        ],
      },
      {
        path: 'concierge-reservation/:uuid?/*',
        loader: ({ request, params }: { request: Request; params: Params }) => {
          const searchParams = new URL(request.url).searchParams;
          const token = searchParams.get('token');
          const uuid = params.uuid;

          if (token) {
            const auth: AuthResponse = {
              user: 'concierge_user',
              token: token,
              new: false,
              is_concierge_member: true,
            };
            myStore.set(AuthAtom, auth);
            return redirect(`../reservation/${uuid}/receipt`);
          }

          return { uuid: uuid };
        },
        element: <ConciergeMember />,
      },
      {
        path: 'reservation/:uuid/receipt',
        element: <Frictionless />,
        children: [
          {
            path: '',
            loader: ({ request }: { request: Request }) => {
              const json = localStorage.getItem('frictionless_auth'); // atomWithStorage, but load it directly to skip the holding pattern while it loads

              const auth: AuthResponse | null = json
                ? (JSON.parse(json) as AuthResponse)
                : null;
              if (auth?.token) {
                return redirect('./details');
              }

              const number = new URL(request.url).searchParams.get('phone');
              if (number) {
                try {
                  const phone = getPhone(number);
                  if (phone) {
                    myStore.set(FLPhoneAtom, phone);
                    void verify(phone.formatInternational()!);
                    return redirect('./auth');
                  }
                } catch (e) {
                  console.error(e);
                }
              }

              return redirect('./phone');
            },
          },
          {
            path: 'phone',
            element: <FLPhone />,
          },
          {
            path: 'auth',
            element: <FLAuth />,
          },
          {
            path: 'details',
            element: <FLDetails />,
          },
          {
            path: 'tip',
            element: <FLCustomTip />,
          },
        ],
      },
      {
        path: 'cancel/:uuid',
        element: <CancelLink />,
        children: [
          {
            path: '',
            loader: ({
              request,
              params,
            }: {
              request: Request;
              params: Params;
            }) => {
              const uuid = params.uuid;
              const signature = new URL(request.url).searchParams.get('sig');

              if (uuid) {
                if (signature) {
                  myStore.set(SignatureAtom, { [uuid]: signature });
                  return redirect('./confirm');
                }

                const json = localStorage.getItem('cancel_signature'); // atomWithStorage, but load it directly to skip the holding pattern while it loads
                const saved_signature: Record<string, string> | null = json
                  ? (JSON.parse(json) as Record<string, string>)
                  : null;
                if (saved_signature?.[uuid]) {
                  return redirect('./confirm');
                }
              }

              return redirect('./404');
            },
          },
          {
            path: '404',
            element: <DLNotFound />,
          },
          {
            path: 'confirm',
            element: <CLConfirm />,
          },
          {
            path: 'canceled',
            element: <DLCanceled />,
          },
        ],
      },
      {
        path: 'restaurant/',
        element: <WidgetIndex />,
      },
      {
        path: 'restaurant/:id',
        element: <Widget />,
        children: [
          {
            path: '',
            loader: () => redirect('./booking'),
          },
          {
            path: 'booking',
            element: <WidgetBooking />,
          },
          {
            path: 'info',
            element: <WidgetInfo />,
          },
          {
            path: 'eventinfo',
            element: <WidgetEventInfo />,
          },
          {
            path: 'phone',
            element: <WidgetPhoneConfirm />,
          },
          {
            path: 'eventphone',
            element: <WidgetEventPhoneConfirm />,
          },
          {
            path: 'payment',
            element: <WidgetPayment />,
          },
          {
            path: 'eventpayment',
            element: <WidgetEventPayment />,
          },
          {
            path: 'confirm',
            element: <WidgetConfirm />,
          },
          {
            path: 'eventconfirm',
            element: <WidgetEventConfirm />,
          },
          {
            path: 'confirmation',
            element: <WidgetConfirmation />,
          },
          {
            path: 'eventconfirmation',
            element: <WidgetEventConfirmation />,
          },
        ],
      },
      {
        path: '/',
        loader: () => redirect('./restaurant'),
      },
    ];

    if (Config.data.environment !== 'production') {
      routes.push({
        path: '/iframe',
        element: <Iframe />,
      });

      routes.push({
        path: '/booking_url',
        element: <BookingUrlIndex />,
      });

      routes.push({
        path: '/booking_url/:id',
        element: <BookingUrl />,
        children: [
          {
            path: '',
            element: <BookingUrlBooking />,
          },
        ],
      });
    }

    const router = sentryCreateBrowserRouter(routes);

    if (import.meta.hot) {
      import.meta.hot.dispose(() => router.dispose());
    }

    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <JotaiProvider store={myStore}>
          <RouterProvider router={router} />
        </JotaiProvider>
      </React.StrictMode>
    );
  })
  .catch(() => console.error('Missing Config'));
